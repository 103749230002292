import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import './Home.css';
import Header from '../components/Header';
import withAuth from '../components/withAuth';
import supabase from '../lib/supabaseClient';
import './AddSource.css';

function AddSource() {
    const [formData, setFormData] = useState({
        source_name: '',
        source_type: '',
        source_url: '',
        source_sender_email: '',
        parse_rule: ''
    });
    const [message, setMessage] = useState('');

    const sourceTypes = [
        '网页',
        '知乎动态rss',
        'Newsletter',
        '微信公众号rss'
    ];

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('正在添加...');

        const { data, error } = await supabase
            .from('source')
            .insert([formData]);

        if (error) {
            console.error('Error inserting data:', error);
            setMessage('添加失败，请重试。');
        } else {
            console.log('Data inserted successfully:', data);
            setMessage('添加成功！');
            setFormData({
                source_name: '',
                source_type: '',
                source_url: '',
                source_sender_email: '',
                parse_rule: ''
            });
        }
    };

    return (
        <div className="App">
            <Header />
            <div className='header_spacer'></div>
            <div className='add_container'>
                <div className="add">
                    <h2>添加新的数据源</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="source_name">数据源名字：</label>
                            <input
                                type="text"
                                id="source_name"
                                name="source_name"
                                value={formData.source_name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>数据源类型：</label>
                            <div className="radio-group">
                                {sourceTypes.map((type) => (
                                    <label key={type}>
                                        <input
                                            type="radio"
                                            name="source_type"
                                            value={type}
                                            checked={formData.source_type === type}
                                            onChange={handleChange}
                                            required
                                        />
                                        {type}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label htmlFor="source_url">数据源 URL（网页和rss必选）：</label>
                            <input
                                type="text"
                                id="source_url"
                                name="source_url"
                                value={formData.source_url}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="source_sender_email">发送者邮箱（可选）：</label>
                            <input
                                type="email"
                                id="source_sender_email"
                                name="source_sender_email"
                                value={formData.source_sender_email}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="parse_rule">解析 URL(网页类型必选)：</label>
                            <input
                                type="text"
                                id="parse_rule"
                                name="parse_rule"
                                value={formData.parse_rule}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit">确认添加</button>
                    </form>
                    {message && <p className={`message ${message.includes('成功') ? 'success' : 'error'}`}>{message}</p>}
                </div>
            </div>
      </div>

        
    );
}

export default withAuth(AddSource);
