import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <div className='headline_container'>
        <p className='headline'>AGI is Arriving..
          <span class="headline-glitch">.</span>
        </p>
        <p className="motto">... and that a brave new world of the agents, by the agents, for the agents, shall arise from the silica.</p>
      </div>
    </header>
  );
}

export default Header;
