import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import supabase from '../lib/supabaseClient';
import './SideBar.css'
import withAuth from './withAuth'

function SideBar({session}) {
  const [groupedSources, setGroupedSources] = useState({});

  console.log('print session')
  console.log(session)
  console.log(session.user.id)

  useEffect(() => {
    getSources();
  }, []);
  
  async function getSources() {
    const { data, error } = await supabase
      .from("user_source")
      .select(`user_id,
              source_id,
              source: source_id (
                source_type,
                source_name
              )
              `)
      .order('created_at', { ascending: false });

    console.log(data)

    if (error) {
      console.error('Error fetching sources:', error);
      return;
    }

    // 按 source_type 分组
    const grouped = data.reduce((acc, source) => {
      if (!acc[source.source.source_type]) {
        acc[source.source.source_type] = [];
      }
      acc[source.source.source_type].push(source.source);
      return acc;
    }, {});

    setGroupedSources(grouped);
  }

  return (
    <div className="side_bar">
      <p className='added_source'>已关注站点</p>
      <p className='add_source'><Link to="/all-sources">增加关注</Link></p>
      {Object.entries(groupedSources).map(([sourceType, sources]) => (
        <div key={sourceType}>
          <h5 className='source_type'>{sourceType}</h5>
          <ul className='source_ul'>
            {sources.map((source, index) => (
              <li key={index} className='source'>{source.source_name}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default withAuth(SideBar);