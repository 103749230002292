import React, { useEffect, useState } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import supabase from '../lib/supabaseClient';


function withAuth(WrappedComponent) {
  return function WithAuthComponent(props) {
    const [session, setSession] = useState(null);

    useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session);
      });

      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session);
      });

      return () => subscription.unsubscribe();
    }, []);

    if (!session) {
      return <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />;
    }

    return <WrappedComponent {...props} session={session} />;
  };
}

export default withAuth;