import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import './Home.css';
import NewsCard from '../components/NewsCard';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import withAuth from '../components/withAuth';

ReactGA.initialize('G-FK4YHLDPRT');

function Home({session}) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  console.log(session);

  return (
    <div className="App">
      <Header />
      <div className='header_spacer'></div>
      <div className='notice'>
        {/* <p>Site is Under Construction</p> */}
        <SideBar />
      </div>
      <div className='news_card_conatiner'>
        <NewsCard />
      </div>

    </div>
  );
}

export default withAuth(Home);
